import { Component, OnInit, Input, Output, EventEmitter,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import{ NgxConfirmBoxService} from './ngx-confirm-box.service';

@Component({
  selector: 'ngx-confirm-box',
  template: `<div class="overlay" [ngStyle]="{'background-color':confirmaray.bgColor}" *ngIf="showHide" >
    <div class="confirmbox-container">
	<h3 *ngIf="confirmaray.confirmHeading">{{confirmaray.confirmHeading}}</h3>
	<p>{{confirmaray.confirmContent}}</p>
	<div class="col-md-12 btn-grp col-sm-12">
	<button class="cancel-btn btn-default btn btn-sm" (click)="hideConfirm()">{{confirmaray.confirmCanceltext}}</button>
	<button class="btn-primary btn btn-sm confirm-btn" (click)="returConfirmBox()">{{confirmaray.confirmOkaytext}}</button></div>
	</div>
</div>`,
  styles: [`.overlay{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;z-index:2}.confirmbox-container{position:absolute;top:40%;left:40%;width:300px;background:#fff;padding:15px;border-radius:6px}.confirmbox-container h3{padding:0 0 10px;margin:5px;border-bottom:1px solid #c5c5c6;font-size:18px}.confirmbox-container p{margin:0;padding:3px;text-align:center}.btn-grp{text-align:center;padding-top:10px}.confirm-btn{margin:0 10px}`]
})
export class NgxConfirmBoxComponent implements OnInit, OnDestroy {

   @Input() bgColor            = 'rgba(0,0,0,0.5)'; //overlay background color
   @Input() confirmContent     = 'Are you sure want to delete this?'; 
   @Input() confirmHeading     = 'Confirmation'; 
   @Input() confirmCanceltext  = 'Cancel'; 
   @Input() confirmOkaytext    = 'Okay'; 

  //confirmHeading:string;
  showHide  = false; 
  returnval = false; 
  confirmaray:any = {bgColor:'rgba(0,0,0,0.5)',confirmHeading:'',confirmContent:'Are you sure want to delete this?',confirmCanceltext:'No',confirmOkaytext:'Yes',show:this.showHide};
  subscription: Subscription;
  
  constructor(private confirmService : NgxConfirmBoxService) { 
   
  }
  @Output() confirmEvt = new EventEmitter<boolean>();
 
  ngOnInit() {

  this.subscription =this.confirmService.getState().subscribe( showHide => {
  this.showHide = showHide });
  this.confirmaray.show    = this.showHide;

  this.confirmaray.bgColor           = this.bgColor;
  this.confirmaray.confirmHeading    = this.confirmHeading;
  this.confirmaray.confirmContent    = this.confirmContent;
  this.confirmaray.confirmCanceltext = this.confirmCanceltext;
  this.confirmaray.confirmOkaytext   = this.confirmOkaytext;
  }

  hideConfirm(){
   
    this.showHide                       = false;
    this.confirmaray.show               = false;
    this.confirmEvt.emit(false); 
  }

  returConfirmBox(){
    this.confirmaray.show   = false;
    this.showHide           = false;
    this.confirmEvt.emit(true);   
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
